var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-7 col-xs-12 col-md-7"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.searchTerm = null}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTable',{staticClass:"mt-0 mr-0",staticStyle:{"padding-top":"2px !important"},attrs:{"has-add-function":true,"cols-to-delete":_vm.colsToDelete,"file-name":"species","table-id":"speciesTable"},on:{"add-action":function($event){return _vm.openCreateSpeciesDialog()}}}),_c('HelpPopUpV2',{attrs:{"help-page-component":"SpeciesListing","no-padding":true}})],1)],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-overlay',{attrs:{"value":_vm.loading.species,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            },"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.allSpecies,"items-per-page":-1,"search":_vm.searchTerm,"options":_vm.tableOptions,"sort-by":['Species.title'],"calculate-widths":"","dense":"","fixed-header":"","id":"speciesTable","item-key":"Species.id"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.allSpecies.indexOf(item) + 1))])]}},{key:"item.Species.title",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Species.title))])]}},{key:"item.Species.productgroups",fn:function(ref){
            var item = ref.item;
return _vm._l((item.Species.productGroupNames),function(product,idx){return _c('v-chip',{key:product + '_' + idx,staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(_vm._s(product))])})}},{key:"item.Parent.title",fn:function(ref){
            var item = ref.item;
return [(item.Parent.title != null)?_c('span',[_vm._v(_vm._s(item.Parent.title))]):_vm._e(),(item.Parent.title == null)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('message.pleaseProvide')))]):_vm._e()]}},{key:"item.Species.type",fn:function(ref){
            var item = ref.item;
return [(item.Species.type == 'HW')?_c('span',[_vm._v(_vm._s(_vm.$t('message.hardwood')))]):_vm._e(),(item.Species.type == 'SW')?_c('span',[_vm._v(_vm._s(_vm.$t('message.softwood')))]):_vm._e()]}},{key:"item.Species.hscode",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.Species.hscode != null)?_c('span',[_vm._v(_vm._s(_vm.$t('message.default') + ": " + item.Species.hscode))]):_vm._e(),(item.Species.hscode_veneer != null)?_c('span',[_vm._v(_vm._s(_vm.$t('message.veneer') + ": " + item.Species.hscode_veneer))]):_vm._e()])]}},{key:"item.Species.id",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditSpeciesDialog(item.Species.id, item.Species.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateSpecies'))+" ")],1),(false)?_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openConvertDialog(item.Species.id, item.Species.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"blue darken-1"}},[_vm._v("fas fa-file-export")]),_vm._v(_vm._s(_vm.$t('message.convertToAnotherSpecies'))+" ")],1):_vm._e(),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteSpecies(item.Species.id, item.Species.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteSpecies')))],1)],1)],1)],1)]}}])})],1),_c('AddSpecies',{attrs:{"species-id":_vm.selectedSpeciesId,"species-name":_vm.selectedSpeciesName,"dialog":_vm.editDialog,"page-key":_vm.pageKey,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.speciesUpdated,"create-done":_vm.speciesCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }